import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();

export const getLoggedInUser = () => {
  const user = sessionStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//login
export const postLogin = (data) => api.create(url.API_USER_LOGIN, data);

//Web
export const insertWeb = (data) => api.create(url.API_WEB_INSERT, data);
export const getAllWeb = (data) => api.get(url.API_WEB_GET_ALL, data);
export const updateWeb = (id, data) =>
  api.update(`${url.API_WEB_UPDATE}/${id}`, data);
export const deleteWeb = (id, data) =>
  api.delete(`${url.API_WEB_DELETE}/${id}`, data);

//team
export const insertTeam = (data) => api.create(url.API_TEAM_INSERT, data);
export const getAllTeam = (data) => api.get(url.API_TEAM_GET_ALL, data);
export const updateTeam = (id, data) =>
  api.update(`${url.API_TEAM_UPDATE}/${id}`, data);
export const deleteTeam = (id, data) =>
  api.delete(`${url.API_TEAM_DELETE}/${id}`, data);




//Role
export const insertRole = (data) => api.create(url.API_ROLE_INSERT, data);
export const updateRole = (id, data) =>
  api.update(`${url.API_ROLE_UPDATE}/${id}`, data);
export const deleteRole = (id, data) =>
  api.delete(`${url.API_ROLE_DELETE}/${id}`, data);
export const getAllRole = (data) => api.get(url.API_ROLE_GETALL, data);
export const getPagingRole = (data) => api.get(url.API_ROLE_GET_PAGING, data);
export const getRoleById = (id, data) =>
  api.get(`${url.API_ROLE_GET_PAGING_BY_ID}/${id}`, data);

//Action
export const insertAction = (data) => api.create(url.API_ACTION_INSERT, data);
export const updateAction = (id, data) =>
  api.update(`${url.API_ACTION_UPDATE}/${id}`, data);
export const deleteAction = (id, data) =>
  api.delete(`${url.API_ACTION_DELETE}/${id}`, data);
export const getAllAction = (data) => api.get(url.API_ACTION_GETALL, data);
export const getPagingAction = (data) =>
  api.get(url.API_ACTION_GET_PAGING, data);
export const getActionById = (id, data) =>
  api.get(`${url.API_ACTION_GET_PAGING_BY_ID}/${id}`, data);

//RoleAction
export const insertRoleAction = (data) =>
  api.create(url.API_ROLEACTION_INSERT, data);
export const updateRoleAction = (id, data) =>
  api.update(`${url.API_ROLEACTION_UPDATE}/${id}`, data);
export const insertManyRoleAction = (data) =>
  api.create(url.API_ROLEACTION_INSERTMANY, data);
export const updateManyRoleAction = (id, data) =>
  api.update(`${url.API_ROLEACTION_UPDATEMANY}/${id}`, data);
export const deleteRoleAction = (id, data) =>
  api.delete(`${url.API_ROLEACTION_DELETE}/${id}`, data);
export const getPagingRoleAction = (data) =>
  api.get(url.API_ROLEACTION_GET_PAGING, data);
export const getRoleActionById = (id, data) =>
  api.get(`${url.API_ROLEACTION_GET_PAGING_BY_ID}/${id}`, data);

//User
export const insertUser = (data) => api.create(url.API_USER_INSERT, data);
export const updateUser = (id, data) =>
  api.update(`${url.API_USER_UPDATE}/${id}`, data);
export const deleteUser = (id, data) =>
  api.delete(`${url.API_USER_DELETE}/${id}`, data);
export const getPagingUser = (data) => api.get(url.API_USER_GET_PAGING, data);
export const getUserById = (id, data) =>
  api.get(`${url.API_USER_GET_PAGING_BY_ID}/${id}`, data);


