import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isPosts, setIsPost] = useState(false);
  const [isWebs, setIsWebs] = useState(false);
  const [isTeams, setisTeams] = useState(false);
  const [isTeams2, setisTeams2] = useState(false);
  const [isTeams3, setisTeams3] = useState(false);
  const [isFootball, setFootball] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Teams") {
      setisTeams(false);
    }
    if (iscurrentState !== "Teams2") {
      setisTeams2(false);
    }
    if (iscurrentState !== "Teams3") {
      setisTeams3(false);
    }
    if (iscurrentState !== "Webs") {
      setIsWebs(false);
    }
  }, [history, iscurrentState, isDashboard, isPosts, isWebs, isFootball, isTeams, isTeams2, isTeams3]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    // {
    //   id: "webs",
    //   label: "Webs",
    //   icon: "ri-dashboard-2-line",
    //   link: "/#",
    //   stateVariables: isWebs,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsWebs(!isWebs);
    //     setIscurrentState("Webs");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "webs",
    //       label: "Webs",
    //       link: "/webs",
    //     }
    //   ],
    // },
    {
      id: "teams",
      label: "Webs",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isTeams,
      click: function (e) {
        e.preventDefault();
        setisTeams(!isTeams);
        setIscurrentState("Teams");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "teams",
          label: "Webs",
          link: "/teams",
        }
      ]
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
