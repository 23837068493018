import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  Input,
  Button,
  Form,
  message,
  Space,
  Select,
  Drawer,
  Tooltip,
  Table,
  Tag
} from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import {
  insertTeam,
  getAllTeam,
  updateTeam,
  deleteTeam,
} from "../../helpers/helper";
import toSlug from "../../common/function";
import TextArea from "antd/lib/input/TextArea";
const getAllData = async (_prams) => {
  const params = _prams
    ? _prams
    : {
        pageIndex: 1,
        pageSize: 10,
        search: "",
      };
  const dataRes = await getAllTeam(params);

  const data =
    dataRes?.data &&
    dataRes?.data.length > 0 &&
    dataRes?.data.map((item) => {
      return {
        id: item._id,
        name: item.name,
        webs: item.webs
      };
    });
  return dataRes?.data ? data : [];
};

function isValidURL(url) {
  var urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlRegex.test(url);
}

function addHttpsToUrl(url) {
  if (!url.startsWith("https://")) {
      url = "https://" + url;
  }
  return url;
}
const Tags = () => {
  document.title = "Management Team";

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [tagName, setTagName] = useState("");
  const [listTeam, setListTeam] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const dataRes = await getAllData();
      setListTeam(dataRes);
    }
    fetchData();
  }, []);

  const handleRefreshCreate = async () => {
    form.resetFields();
  };
  const handleRefreshSearch = async () => {
    const dataRes = await getAllData();
    setListTeam(dataRes);
    formSearch.resetFields();
  };
  const handleRefresh = async () => {
    form.resetFields();
    const dataRes = await getAllData();
    setListTeam(dataRes);
  };
  const onFinish = async (data) => {
    let bool = false;
    const arrweb = data.webs.split('\n').filter(i => i);
    for (let index = 0; index < arrweb.length; index++) {
      const element = arrweb[index];
      if(!isValidURL(addHttpsToUrl(element))){
          message.error(`${element} url không hợp lệ`)
          bool = true
          break;
      }
    }
    if(bool){
      return;
    }
    const dataReq = {
      name: data.name,
      webs: arrweb
    };

    if (!data.id) {
      //Save
      const dataRes = await insertTeam(dataReq);
      dataRes.success
        ? message.success(`Lưu thành công! ${dataRes.message}`)
        : message.error(`Save Failed! ${dataRes.message}`);
      if (dataRes.success) {
        onClose();
      }
    } else {
      //Update
      const dataRes = await updateTeam(data.id, dataReq);
      dataRes.success
        ? message.success(`Update Success! ${dataRes.message}`)
        : message.error(`Update Failed! ${dataRes.message}`);
      if (dataRes.success) {
        onClose();
      }
    }

    form.resetFields();
    const dataRes = await getAllData();
    setListTeam(dataRes);
  };
  const onFinishFailed = () => {
    // message.error("Save failed!");
  };
  const handleSearch = async () => {

      const dataForm = formSearch.getFieldsValue();
      if (
        dataForm.textSearch === undefined
      ) {
        dataForm.textSearch = '';
      }

    const data = listTeam.filter(item => {
        if(toSlug(item.urlTeam).split("-").join(" ").search(toSlug(dataForm.textSearch).split("-").join(" ")) !== -1){
           return true;
        }
      })
  
    setListTeam(data);
  };
  const onDelete = async (key) => {
    const dataRes = await deleteTeam(key);
    dataRes.status === 1
      ? message.success(`Xóa thành công! ${dataRes.message}`)
      : message.error(`Xóa thất bại! ${dataRes.message}`);

    handleRefresh();
  };
  const onEdit = (key) => {
    const dataEdit = listTeam.filter((item) => item.id === key);
    form.setFieldsValue({
      name: dataEdit[0].name,
      id: dataEdit[0].id,
      webs:  dataEdit[0].webs.join("\n")
    });
    showDrawer();
    setDrawerTitle("Sửa Team");
  };
  const onClose = () => {
    setVisibleForm(false);
  };
  const showDrawer = () => {
    setVisibleForm(true);
  };
  const handleNewTag = () => {
    setDrawerTitle("Thêm Team");
    showDrawer();
    form.resetFields();
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "List web",
      dataIndex: "webs",
      render: (_, record) => {
        const listWebName = _?.map((item, index) => {
          return (
            <Tag color="default" key={index}>
              {item}
            </Tag>
          );
        });
        return listWebName;
      },
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listTeam.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.id)}
              />
            </Tooltip>
            <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.id)}
              />
            </Tooltip>
          </Space>
        ) : null,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Team" pageTitle="Quản lí team" />
          <div>
            <Col>
              <Drawer
                title={drawerTitle}
                placement={"right"}
                width={"60%"}
                onClose={onClose}
                open={visibleForm}
                bodyStyle={{
                  paddingBottom: 80,
                }}
                style={{ marginTop: "70px" }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row>
                    <Col sm={4} hidden={true}>
                      <Form.Item name="id" label="Id">
                        <Input name="id" />
                      </Form.Item>
                    </Col>
      
                  </Row>
              <Form.Item
                  name="name"
                  label="name"
                >
                  <Input
                    placeholder="Enter name"
                    name="name"
                    allowClear={true}
                  />
                </Form.Item>

                <Form.Item
                  name="webs"
                  label="webs"
                >
                  <TextArea rows={20}
                    placeholder="Enter webs"
                    name="webs"
                    allowClear={true}
                  />
                </Form.Item>
                  <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Lưu
                      </Button>

                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => handleRefreshCreate()}
                      >
                        Làm mới trang
                      </Button>
                    </Space>
                  </Form.Item>
                </Form>
              </Drawer>
            </Col>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={formSearch}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col sm={4} hidden={true}>
                    <Form.Item name="id" label="Id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  {/* <Col sm={4}>
                    <Form.Item
                      name="textSearch"
                      label="Từ khóa"
                      rules={[
                        {
                          required: false,
                          message: "Please input text name!",
                        },
                        {
                          type: "textSearch",
                        },
                        {
                          type: "string",
                          min: 1,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter tag name"
                        name="textSearch"
                        allowClear={true}
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                      />
                    </Form.Item>
                  </Col> */}
                </Row>
                <Form.Item>
                  <Space>
                    {/* <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleSearch()}
                    >
                      Tìm kiếm
                    </Button> */}
                    <Button type="primary" onClick={handleNewTag}>
                    Tạo mới
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefreshSearch()}
                    >
                      Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          {/* datatable tags */}
          <div>
            <Table
              columns={columns}
              dataSource={listTeam}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tags;