import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../common/BreadCrumb";
import {
  Input,
  Button,
  Form,
  message,
  Space,
  Select,
  Drawer,
  Tooltip,
  Table,
  Tag
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import {
  insertWeb,
  getAllWeb,
  updateWeb,
  deleteWeb,
  getAllTeam
} from "../../helpers/helper";
import toSlug from "../../common/function";
const { Option } = Select;
const getAllData = async (_prams) => {
  const params = _prams
    ? _prams
    : {
        pageIndex: 1,
        pageSize: 10,
        search: "",
      };
  const dataRes = await getAllWeb(params);

  const data =
    dataRes?.data &&
    dataRes?.data.length > 0 &&
    dataRes?.data.map((item) => {
      return {
        id: item._id,
        urlWeb: item.urlWeb,
        code: item.code,
        classQuerys:item.classQuerys,
        description: item.description,
      };
    });
  return dataRes?.data ? data : [];
};

const Tags = () => {
  document.title = "Management Web";

  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const [visibleForm, setVisibleForm] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [tagName, setTagName] = useState("");
  const [listWeb, setListWeb] = useState([]);
  const [listTeam, setListTeam] = useState([]);
  const [idTeamSelect, setIdTeamSelect] = useState("")

  useEffect(() => {
    async function fetchData() {
      const dataRes = await getAllData();
      setListWeb(dataRes);
      const dataR = await getAllTeam()
      setListTeam(dataR.data);
    }
    fetchData();

  
  }, []);

  const handleRefreshCreate = async () => {
    form.resetFields();
  };
  const handleRefreshSearch = async () => {
    const dataRes = await getAllData();
    setListWeb(dataRes);
    formSearch.resetFields();
  };
  
  const onFinish = async (data) => {
    const dataReq = {
      urlWeb: data.urlWeb,
      code: data.code,
      classQuerys:data.classQuerys,
      description: data.description,
    };

    if (!data.id) {
      //Save
      const dataRes = await insertWeb(dataReq);
      dataRes.success
        ? message.success(`Lưu thành công! ${dataRes.message}`)
        : message.error(`Save Failed! ${dataRes.message}`);
      if (dataRes.success) {
        onClose();
      }
    } else {
      //Update
      const dataRes = await updateWeb(data.id, dataReq);
      dataRes.success
        ? message.success(`Update Success! ${dataRes.message}`)
        : message.error(`Update Failed! ${dataRes.message}`);
      if (dataRes.success) {
        onClose();
      }
    }

    form.resetFields();
    const dataRes = await getAllData();
    setListWeb(dataRes);
  };


  const handleChangeQuery = (value) => {
    setIdTeamSelect(value)
  };
  const onFinishFailed = () => {
    // message.error("Save failed!");
  };

  const handleRefresh = async () => {
    form.resetFields();
    const dataRes = await getAllData();
    setListWeb(dataRes);
  };

  const handleSearch = async () => {

      const dataForm = formSearch.getFieldsValue();
      if (
        dataForm.textSearch === undefined
      ) {
        dataForm.textSearch = '';
      }

    const data = listWeb.filter(item => {
        if(toSlug(item.urlWeb).split("-").join(" ").search(toSlug(dataForm.textSearch).split("-").join(" ")) !== -1){
           return true;
        }
      })
  
    setListWeb(data);
  };

  const onEdit = (key) => {
    const dataEdit = listWeb.filter((item) => item.id === key);
    form.setFieldsValue({
      urlWeb: dataEdit[0].urlWeb,
      code: dataEdit[0].code,
      classQuerys:dataEdit[0].classQuerys,
      description: dataEdit[0].description,

      tagName: dataEdit[0].urlWeb,
      tagSlug: dataEdit[0].code,
      id: dataEdit[0].id,
    });
    showDrawer();
    setDrawerTitle("Sửa Web");
  };
  const onClose = () => {
    setVisibleForm(false);
  };
  const showDrawer = () => {
    setVisibleForm(true);
  };
  const handleNewTag = () => {
    setDrawerTitle("Thêm Web");
    showDrawer();
    form.resetFields();
  };
  const onDelete = async (key) => {
    const dataRes = await deleteWeb(key);
    dataRes.status === 1
      ? message.success(`Xóa thành công! ${dataRes.message}`)
      : message.error(`Xóa thất bại! ${dataRes.message}`);

    handleRefresh();
  };

  const columns = [
    {
      title: "Link Web",
      dataIndex: "urlWeb",
    },
    {
      title: "Link Code",
      dataIndex: "code",
    },
    {
      title: "Query",
      dataIndex: "classQuerys",
      render: (_, record) => {
        const listWebName = _?.map((item, index) => {
          return (
            <Tag color="default" key={index}>
              {item}
            </Tag>
          );
        });
        return listWebName;
      },
    },
    {
      title: "description",
      dataIndex: "description",
    },
    {
      title: "Hành động",
      dataIndex: "",
      render: (_, record) =>
        listWeb.length >= 1 ? (
          <Space>
            <Tooltip title="Sửa">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                size="small"
                onClick={() => onEdit(record.id)}
              />
            </Tooltip>
            {/* <Tooltip title="Xóa">
              <Button
                type="danger"
                shape="circle"
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => onDelete(record.key)}
              />
            </Tooltip> */}
          </Space>
        ) : null,
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Web" pageTitle="Quản lí link web" />
          <div>
            <Col>
              <Drawer
                title={drawerTitle}
                placement={"right"}
                width={"60%"}
                onClose={onClose}
                open={visibleForm}
                bodyStyle={{
                  paddingBottom: 80,
                }}
                style={{ marginTop: "70px" }}
              >
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Row>
                    <Col sm={4} hidden={true}>
                      <Form.Item name="id" label="Id">
                        <Input name="id" />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        name="urlWeb"
                        label="Link Web"
                        rules={[
                          {
                            required: true,
                            message: "Please input urlWeb!",
                          },
                          {
                            type: "urlWeb",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter urlWeb"
                          name="urlWeb"
                          allowClear={true}
                        />
                      </Form.Item>

                      <Form.Item
                        name="code"
                        label="Link Code"
                        rules={[
                          {
                            required: true,
                            message: "Please input code!",
                          },
                          {
                            type: "Code",
                          },
                          {
                            type: "string",
                            min: 1,
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter tag slug"
                          name="tagSlug"
                          allowClear={true}
                        />
                      </Form.Item>

                      <Form.Item>
                    <Space>
                      <Button type="primary" htmlType="submit">
                        Lưu
                      </Button>

                      <Button
                        type="primary"
                        htmlType="button"
                        onClick={() => handleRefreshCreate()}
                      >
                        Làm mới trang
                      </Button>
                    </Space>
                  </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Drawer>
            </Col>
          </div>
          <Row>
            <Col xs={12}>
              <Form
                form={formSearch}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row>
                  <Col sm={4} hidden={true}>
                    <Form.Item name="id" label="Id">
                      <Input name="id" />
                    </Form.Item>
                  </Col>
                  <Col sm={4}>
                    <Form.Item
                      name="textSearch"
                      label="Từ khóa"
                      rules={[
                        {
                          required: false,
                          message: "Please input text name!",
                        },
                        {
                          type: "textSearch",
                        },
                        {
                          type: "string",
                          min: 1,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter tag name"
                        name="textSearch"
                        allowClear={true}
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                      />
                    </Form.Item>

                  <Form.Item
                    name="team"
                    label="team"
                  >
                    <Select
                      placeholder="Select team"
                      allowClear
                      onChange={handleChangeQuery}
                      name="team"
                    >
                      {listTeam.length > 0 &&
                        listTeam.map((item) => {
                          return (
                            <Option key={item._id} value={item._id}>
                              {item.name}
                            </Option>
                          );
                        })}
                    </Select>
                </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Space>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleSearch()}
                    >
                      Tìm kiếm
                    </Button>
                    <Button type="primary" onClick={handleNewTag}>
                    Tạo mới
                    </Button>
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => handleRefreshSearch()}
                    >
                      Làm mới trang
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          {/* datatable tags */}
          <div>
            <Table
              columns={columns}
              dataSource={listWeb}
              pagination={{ pageSize: 10 }}
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Tags;