import React from "react";
import { Redirect } from "react-router-dom";


import Webs from "../pages/Webs";
import Teams from "../pages/Teams";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Users from "../pages/Authentication/Users";
import Roles from "../pages/Authentication/Role";
import Permisstions from "../pages/Authentication/RoleAction";
const authProtectedRoutes = [

  // { path: "/webs", component: Webs },
  { path: "/teams", component:  Teams},
  { path: "/users", component: Users },
  { path: "/roles", component: Roles },
  { path: "/permisstions", component: Permisstions },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
